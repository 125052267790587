import styled, { css } from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';
import { tabletMedia } from '@vaza-eu/vaza/dist/styles';

export const StyledWrapper = styled.div<{ isPromo?: boolean }>`
  position: relative;
  
  ${({ isPromo }) => isPromo && css`
    background-color: #ffc347;
  `};

  @media (${tabletMedia}) {
    width: 474px;
    height: 84dvh;
    max-height: 679px;
    border-radius: 17px;
  }
  
  * {
    font-family: 'Roboto Condensed' !important;
  }
`;

export const StyledInnerWrapper = styled.div<{ isPromo?: boolean }>`;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 110px 0;
  height: 700px;

  ${({ isPromo }) => isPromo && css`
    padding: 40px 23px;
    height: 100dvh;
  `};

  @media (${desktopMedia}) {
    padding: 40px;
    height: 100%;
  }
`;

export const StyledStar1 = styled.div`
  position: absolute;
  top: 17px;
  right: 59px;
  scale: 0.5;
  
  * {
    fill: #ffffff;
  }
`;

export const StyledStar2 = styled.div`
  position: absolute;
  top: 47px;
  right: 30px;
  scale: 1;

  * {
    fill: #ffffff;
  }
`;