import React, { useEffect, useState } from 'react';
import { StyledInnerWrapper, StyledStar1, StyledStar2, StyledWrapper } from './SignIn.styled';
import { FormProvider, useForm } from 'react-hook-form';
import { BackgroundImage } from '../../components/UI/BackgroundImage/BackgroundImage';
import {
  AUTH_FORM_FIELDS,
  AUTH_VALIDATION,
  getInitialValues,
  PageVariantEnum
} from '../../components/Layout/Auth/Auth.constants';
import { LoginInitial } from '../../components/Layout/Auth/LoginInitial/LoginInitial';
import {
  LoginWithEmailAndPassword
} from '../../components/Layout/Auth/LoginWithEmailAndPassword/LoginWithEmailAndPassword';
import {
  RegisterWithEmailAndPassword
} from '../../components/Layout/Auth/RegisterWithEmailAndPassword/RegisterWithEmailAndPassword';
import { RegisterFillInfo } from '../../components/Layout/Auth/RegisterFillInfo/RegisterFillInfo';
import { StarIcon } from '../../components/UI/SVG/StarIcon';
import { useRouter } from '../../lib/hooks/use-router';
import { useAppContext } from '../../context/AppProvider/AppProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from '../../components/UI/Loading/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

type SignInProps = {
  onClose?: () => void;
  isPromo?: boolean;
};

export const SignIn = ({ onClose, isPromo }: SignInProps) => {
  const auth = getAuth();
  const { locale, query } = useRouter();
  const { user: userContext, isFetchingUser } = useAppContext();
  const [user, setUser] = useState(auth.currentUser);
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ...getInitialValues(locale, isPromo),
    },
    resolver: yupResolver(AUTH_VALIDATION),
    shouldFocusError: true,
  });

  const editModal = query.editModal === 'true';

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  const page = form.getValues(AUTH_FORM_FIELDS.page);

  const isInitialPage = page === PageVariantEnum.INITIAL && (!user || editModal) && !isFetchingUser;
  const isLoginPage = page === PageVariantEnum.LOGIN && (!user || editModal) && !isFetchingUser;
  const isRegisterPage = page === PageVariantEnum.REGISTER && (!user || editModal) && !isFetchingUser;
  const isRegisterFillInfoPage = page === PageVariantEnum.REGISTER_FILL_INFO || (user && !userContext?.isFullyRegistered) && !isFetchingUser;
  const isNoneOfPages = (!isInitialPage && !isLoginPage && !isRegisterPage && !isRegisterFillInfoPage) || isFetchingUser;

  useEffect(() => {
    form.watch();
  }, []);

  return (
    <FormProvider {...form}>
      <BackgroundImage bgColor={'#60cffd'} opacity={0.14} size={885} longBg disabled={isPromo}>
        <StyledWrapper isPromo={isPromo}>
          <StyledInnerWrapper isPromo={isPromo}>
            {isInitialPage && <LoginInitial {...{ onClose, isPromo }} />}
            {isLoginPage && <LoginWithEmailAndPassword {...{ onClose, isPromo }} />}
            {isRegisterPage && <RegisterWithEmailAndPassword {...{ onClose, isPromo }} />}
            {isRegisterFillInfoPage && <RegisterFillInfo {...{ onClose, isPromo }} />}
            {isNoneOfPages && <Loading size={100} color={'white'}/>}
          </StyledInnerWrapper>
          {!isNoneOfPages && (
            <>
              <StyledStar1>
                <StarIcon/>
              </StyledStar1>
              <StyledStar2>
                <StarIcon/>
              </StyledStar2>
            </>
          )}
        </StyledWrapper>
      </BackgroundImage>
    </FormProvider>
  );
};