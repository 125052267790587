import { useMutation, UseMutationResult } from 'react-query';
import { functions } from '../../../firebase';
import { httpsCallable } from 'firebase/functions';
import { AddMissingCurrentUserInfoRequest, AddMissingCurrentUserInfoResponse } from '@vaza-eu/shoelessly';

export const useAddMissingUserInfoMutation = (): UseMutationResult<AddMissingCurrentUserInfoResponse, unknown, AddMissingCurrentUserInfoRequest> =>
  useMutation<AddMissingCurrentUserInfoResponse, unknown, AddMissingCurrentUserInfoRequest>(async (data: AddMissingCurrentUserInfoRequest) => {
    const cloudFn = httpsCallable<AddMissingCurrentUserInfoRequest, AddMissingCurrentUserInfoResponse>(functions,
      'addMissingCurrentUserInfo');
    const response = await cloudFn(data);
    return response.data;
  });