import styled from 'styled-components';
import { Button } from '../../../UI/Button/Button';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 25px;
`;

export const StyledTitle = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 32px;
  padding-bottom: 5px;
  font-weight: 1000;
`;

export const StyledDescription = styled.div`
  font-family: 'Apercu Pro';
  font-size: 18px;
`;

export const StyledForgotPasswordMethodItem = styled.button`
  display: flex;
  background-color: white;
  width: 100%;
  height: 128px;
  border-radius: 32px;
  
  :hover {
    outline: 3px solid #60cffd;
  }
`;

export const StyledForgotPasswordMethodItemIcon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin: 24px;
  background-color: #eef4ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledForgotPasswordMethodItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto 0;
`;

export const StyledForgotPasswordMethodItemTopText = styled.div`
  font-family: 'Apercu Pro';
  font-size: 16px;
  text-align: left;
`;

export const StyledForgotPasswordMethodItemBottomText = styled.div`
  font-family: 'Apercu Pro';
  font-weight: 1000;
  font-size: 16px;
  color: black;
  text-align: left;
`;

export const StyledButton = styled(Button)`
  background-color: #fed431;
  border-radius: 50px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Futura Extra Black Condensed';
  font-size: 18px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 20%);
`;
