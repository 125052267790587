import React from 'react';
import {
  StyledBottom,
  StyledBottomText,
  StyledBottomTextBold,
  StyledDivider,
  StyledDividerLine,
  StyledFieldsWrapper,
  StyledMethodsButtonsWrapper,
  StyledSingInButton,
  StyledTitle,
  StyledWrapper
} from './RegisterWithEmailAndPassword.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { AuthInput } from '../AuthInput/AuthInput';
import { SingInMethodButton } from '../SingInMethodButton/SingInMethodButton';
import { useFormContext } from 'react-hook-form';
import { AUTH_FORM_FIELDS, PageVariantEnum } from '../Auth.constants';
import { isRegisterValid } from '../Auth.helpers';

type RegisterWithEmailAndPasswordProps = {
  onClose?: () => void;
};

export const RegisterWithEmailAndPassword = ({ onClose }: RegisterWithEmailAndPasswordProps) => {
  const form = useFormContext();
  const { setValue } = useFormContext();

  const handleContinue = () => {
    if (!isRegisterValid(form)) return;

    setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.REGISTER_FILL_INFO);
  };

  const handleLogin = () => {
    setValue(AUTH_FORM_FIELDS.page, PageVariantEnum.LOGIN);
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'register.signInWithEmail.title'} type={TranslationTypesEnum.Main}/>
      </StyledTitle>
      <StyledFieldsWrapper>
        <AuthInput fieldName={AUTH_FORM_FIELDS.email}/>
        <AuthInput fieldName={AUTH_FORM_FIELDS.password}/>
        <AuthInput fieldName={AUTH_FORM_FIELDS.passwordConfirmation}/>
        <StyledSingInButton onClick={handleContinue}>
          <Content id={'register.signInWithEmail.signIn'} type={TranslationTypesEnum.Main}/>
        </StyledSingInButton>
      </StyledFieldsWrapper>
      <StyledDivider>
        <StyledDividerLine/>
        <Content id={'register.orContinueWith'} type={TranslationTypesEnum.Main}/>
        <StyledDividerLine/>
      </StyledDivider>
      <StyledMethodsButtonsWrapper>
        <SingInMethodButton {...{ onClose }} type={'small'} method={'facebook'}/>
        <SingInMethodButton {...{ onClose }} type={'small'} method={'google'}/>
        <SingInMethodButton {...{ onClose }} type={'small'} method={'apple'}/>
      </StyledMethodsButtonsWrapper>
      <StyledBottom onClick={handleLogin}>
        <StyledBottomText>
          <Content id={'register.alreadyHaveAnAccount'} type={TranslationTypesEnum.Main}/>
        </StyledBottomText>
        <StyledBottomTextBold>
          <Content id={'register.alreadyHaveAnAccountBold'} type={TranslationTypesEnum.Main}/>
        </StyledBottomTextBold>
      </StyledBottom>
    </StyledWrapper>
  );
};